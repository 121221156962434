import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UserGroupsService } from "../user-groups.service";
import { KeyMap, UserGroup } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";

@Component({
    selector: "app-group-list",
    templateUrl: "./user-group-list.component.html"
})
export class UserGroupListComponent implements OnInit, OnDestroy {
    refreshing = false;
    userGroups: UserGroup[];
    selectedUserGroupID: number;
    selectedRows: UserGroup[] = [];

    urls = Constants.urls;

    private routeSubscription: Subscription;
    private userGroupsSubscription: Subscription;

    get tableColumnsSchema(): TableSchema[] {
        return this._tableColumnsSchema;
    }
    set tableColumnsSchema(newValue: TableSchema[]) {
        this._tableColumnsSchema = newValue;
    }

    _tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<UserGroup>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<UserGroup>) => row.name,
            textValue: (row: KeyMap<UserGroup>) => row.name
        },
        {
            header: this.translate.instant("USERS"),
            columnDef: "users",
            width: 160,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<UserGroup>(
                row => row.users?.length.toString(),
                row => row.users?.length.toString(),
                row => !!row.users?.length.toString()
            ),
            textValue: (row: KeyMap<UserGroup>) => row.users?.length ?? "-",
            sortBy: (row: KeyMap<UserGroup>) => row.users?.length ?? "-"
        },
        {
            header: this.translate.instant("DESCRIPTION"),
            columnDef: "description",
            width: 90,
            visible: true,
            translate: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<UserGroup>(
                row => row.description,
                row => row.description,
                row => !!row.description
            ),
            textValue: (row: KeyMap<UserGroup>) => row.description ?? "-",
            sortBy: (row: KeyMap<UserGroup>) => row.description ?? "-"
        }
    ];

    constructor(
        private router: Router,
        private modalService: ModalService,
        private userGroupsService: UserGroupsService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                // Set Title
                this.titleService.setTitle(this.translate.instant("USER_GROUPS"));

                if (event.snapshot.params && event.snapshot.params.id) {
                    this.selectedUserGroupID = parseInt(event.snapshot.params.id, 10);
                    const existingUserGroup = this.userGroups.find(
                        userGroup => this.selectedUserGroupID === userGroup.id
                    );
                    this.selectedRows = existingUserGroup ? [existingUserGroup] : [];
                } else {
                    this.selectedUserGroupID = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.userGroupsSubscription = this.userGroupsService.userGroups.subscribe(userGroups => {
            this.userGroups = [...userGroups];
        });

        setTimeout(() => {
            if (this.selectedUserGroupID) {
                const existingUserGroup = this.userGroups.find(userGroup => this.selectedUserGroupID === userGroup.id);
                this.selectedRows = existingUserGroup ? [existingUserGroup] : [];
            }
        }, 0);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.userGroupsSubscription.unsubscribe();
    }

    selectRow = (userGroup: UserGroup) => {
        this.router.navigate([Constants.urls.accountManagement.userGroups, userGroup.id]);
    };

    async refresh() {
        this.refreshing = true;
        let userGroup = null;
        const userGroups = this.userGroupsService.refreshUserGroups(true).toPromise();
        if (this.selectedUserGroupID) {
            userGroup = this.userGroupsService.refreshUserGroup(this.selectedUserGroupID).toPromise();
        }
        await Promise.all([userGroups, userGroup]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (userGroup: UserGroup) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "USER_GROUP", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple user groups");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (userGroup: UserGroup) => this.userGroupsService.deleteUserGroup(userGroup));
    }
}
