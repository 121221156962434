import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";

import { UserGroupsService } from "../user-groups.service";
import { Role, UserGroup } from "src/app/models/shared";
import { SortEvent, NgbSortableHeader } from "src/app/directives/sortable.directive";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { RolesService } from "../../roles/roles.service";

@Component({
    selector: "app-group",
    templateUrl: "./user-group.component.html"
})
export class UserGroupComponent implements OnInit, OnDestroy {
    userGroup = new UserGroup();
    userGroupId: number;
    userGroupRoles: Role[];

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    sortedByColumn: string;
    sortedByText: string;
    sortColumn: string;
    sortDirection: string;

    private userGroupsSubscription: Subscription;
    constants = Constants;

    loading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userGroupsService: UserGroupsService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private rolesService: RolesService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.userGroupId = parseInt(params.get("id"), 10);
            if (!this.userGroup) return this.cancel();

            this.loading = true;

            this.userGroupsService
                .refreshUserGroup(this.userGroupId)
                .pipe(take(1))
                .subscribe(userGroup => {
                    this.prepUserGroup(userGroup);
                    this.loading = false;
                });
        });
    }

    ngOnInit() {
        this.userGroupsSubscription = this.userGroupsService.userGroups.subscribe(userGroups => {
            const userGroup = userGroups.find(u => u.id === this.userGroupId);
            if (userGroup) this.prepUserGroup(userGroup);
        });
    }

    ngOnDestroy() {
        if (this.userGroupsSubscription) this.userGroupsSubscription.unsubscribe();
    }

    private prepUserGroup(userGroup: UserGroup) {
        this.userGroupRoles = (userGroup.roles || []).map(r => {
            const role = Object.assign({}, this.rolesService.prepRole(r, false), {
                tagName: r.resourceTag && r.resourceTag.name,
                source: "UserGroup"
            });

            return role;
        });

        this.userGroup = Object.assign({}, userGroup);

        // Set Title
        this.titleService.setTitle(this.translate.instant("USER_GROUP") + " - " + this.userGroup.name);
    }

    cancel() {
        this.gotoUserGroups();
    }

    gotoUserGroups() {
        this.router.navigate([Constants.urls.accountManagement.userGroups]);
    }

    async deleteUserGroup() {
        await this.modalService.confirm(
            "DELETE",
            "USER_GROUP",
            async () => {
                const id = this.userGroup.id;
                const result = await this.userGroupsService.deleteUserGroup(this.userGroup);
                if (result) {
                    if (this.userGroupsSubscription) {
                        this.userGroupsSubscription.unsubscribe();
                        this.userGroupsSubscription = null;
                    }
                    this.mixpanelService.sendEvent("delete user group", { id });
                    this.userGroupsService.refreshUserGroups(true);
                    this.gotoUserGroups();
                } else {
                    return false;
                }
            },
            `${this.userGroup.name}`
        );
    }

    edit(userGroup: UserGroup): void {
        this.router.navigate([Constants.urls.accountManagement.userGroups, userGroup.id, "edit"]);
    }

    onSort({ column, direction }: SortEvent, name: string) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortedByColumn = column;
        this.sortedByText = name;
        this.sortColumn = column;
        this.sortDirection = direction;
    }
}
