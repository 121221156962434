import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";
import { UserGroupsComponent } from "./user-groups.component";
import { UserGroupListComponent } from "./user-group-list/user-group-list.component";
import { UserGroupComponent } from "./user-group/user-group.component";
import { UserGroupFormComponent } from "./user-group-form/user-group-form.component";

import { UserGroupListResolverService } from "./user-group-list/user-group-list-resolver.service";

const userGroupRoutes: Routes = [
    {
        path: `${Constants.urls.accountManagement.userGroups}/new`,
        component: UserGroupFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_USER_GROUP",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/GQD1H"
                }
            ]
        }
    },
    {
        path: `${Constants.urls.accountManagement.userGroups}/:id/:action`,
        component: UserGroupFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: `${Constants.urls.accountManagement.userGroups}`,
        component: UserGroupListComponent,
        resolve: {
            userGroup: UserGroupListResolverService
        },
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.USER_GROUPS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/F4D2H"
                },
                {
                    menu: "HELP_MENU.CREATE_USER_GROUP",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/GQD1H"
                }
            ]
        },
        children: [
            {
                path: ":id",
                component: UserGroupComponent,
                data: {
                    help: [
                        {
                            menu: "HELP_MENU.USER_GROUPS",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/F4D2H"
                        },
                        {
                            menu: "HELP_MENU.CREATE_USER_GROUP",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/GQD1H"
                        }
                    ]
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(userGroupRoutes)],
    exports: [RouterModule]
})
export class UserGroupsRouting {}
