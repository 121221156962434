<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading" autocomplete="off">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_USER_GROUP" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_USER_GROUP" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <div class="form-group">
                        <!-- Name -->
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="name"
                            name="name"
                            class="form-control"
                            placeholder="{{ 'NAME' | translate }}"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="userGroup.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ userGroupNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Description -->
                        <label for="description" [ngClass]="{ 'is-invalid': form.submitted && description.errors }">{{ "DESCRIPTION" | translate }} </label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="description"
                            name="description"
                            class="form-control"
                            placeholder="{{ 'DESCRIPTION' | translate }}"
                            [ngClass]="{ 'is-invalid': form.submitted && description.errors }"
                            [(ngModel)]="userGroup.description"
                            maxlength="250"
                            #description="ngModel"
                        />
                        <div *ngIf="description.invalid" class="invalid-feedback">
                            <div *ngIf="description.errors.maxlength">
                                {{ "DESCRIPTION" | translate }} {{ "MUST_BE_AT_MOST" | translate }} 250 {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Users -->
                        <label for="usersSelect">
                            {{ "USERS" | translate }}
                        </label>
                        <zx-users-select name="usersSelect" [(model)]="userGroup.users"></zx-users-select>
                    </div>
                    <div class="form-group mb-0">
                        <!-- Roles -->
                        <label for="rolesSelect">
                            {{ "ROLES" | translate }}
                        </label>
                        <zx-roles-select name="rolesSelect" [(model)]="userGroup.roles"></zx-roles-select>
                    </div>
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
