import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";
import { RolesTableModule } from "../roles/roles-table/roles-table.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { UserGroupsComponent } from "./user-groups.component";
import { UserGroupListComponent } from "./user-group-list/user-group-list.component";
import { UserGroupComponent } from "./user-group/user-group.component";
import { UserGroupFormComponent } from "./user-group-form/user-group-form.component";

// Routes
import { UserGroupsRouting } from "./user-groups.routing";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        UserGroupsRouting,
        RolesTableModule
    ],
    declarations: [UserGroupsComponent, UserGroupListComponent, UserGroupComponent, UserGroupFormComponent]
})
export class UserGroupsModule {}
