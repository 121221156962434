import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { UserGroupsService } from "../user-groups.service";
import { UserGroup } from "src/app/models/shared";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";

@Component({
    selector: "app-group-form",
    templateUrl: "./user-group-form.component.html",
    providers: [TitleCasePipe]
})
export class UserGroupFormComponent implements OnInit, OnDestroy {
    userGroup = new UserGroup();
    userGroupId: number;
    userGroupNames: string[];
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;

    loading = true;
    saving = false;

    constants = Constants;

    private userGroupsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userGroupsService: UserGroupsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.userGroupId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.userGroupId) {
                this.isEdit = true;

                this.userGroupsService.refreshUserGroup(this.userGroupId, true).subscribe(userGroup => {
                    this.userGroup = Object.assign({}, userGroup);
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("USER_GROUP") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.userGroup.name ? this.userGroup.name : "")
        );
    }

    ngOnInit() {
        this.userGroupsService.refreshUserGroups();

        this.userGroupsSubscription = this.userGroupsService.userGroups.subscribe((userGroups: UserGroup[]) => {
            if (this.isEdit) this.userGroupNames = userGroups.filter(u => u.id !== this.userGroupId).map(u => u.name);
            else this.userGroupNames = userGroups.map(u => u.name);
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.userGroupsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.userGroup.name,
            description: this.userGroup.description,
            user_ids: (this.userGroup.users || []).map(g => g.id),
            role_ids: (this.userGroup.roles || []).map(r => r.id)
        };

        if (this.isEdit) {
            const result = await this.userGroupsService.updateUserGroup(this.userGroupId, model);
            if (result) {
                this.userGroupsService.refreshUserGroup(this.userGroupId, true);
                this.router.navigate([Constants.urls.accountManagement.userGroups, this.userGroupId]);
            }
        } else {
            const result = await this.userGroupsService.addUserGroup(model);
            if (result) this.router.navigate([Constants.urls.accountManagement.userGroups, result.id]);
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit) this.router.navigate([Constants.urls.accountManagement.userGroups, this.userGroupId]);
        else this.router.navigate([Constants.urls.accountManagement.userGroups]);
    }
}
