import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { UserGroupsService } from "../user-groups.service";
import { UserGroup } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class UserGroupListResolverService implements Resolve<UserGroup[]> {
    constructor(private tps: UserGroupsService) {}

    resolve(): Observable<UserGroup[]> | Observable<never> {
        return Observable.create((observe: Subscriber<UserGroup[]>) => {
            this.tps.refreshUserGroups().subscribe((userGroups: UserGroup[]) => {
                observe.next(userGroups);
                observe.complete();
            });
        });
    }
}
